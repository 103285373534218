import { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';
import Router from "next/router";

import OrangeButton from '../../../shared_components/buttons/Orange';

const styles = (theme) => ({
  root: {
    flexDirection: 'column',
    minWidth: theme.spacing(42),
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: '#fff',
    paddingBottom: '50px',
    flexWrap: 'initial',
  },
  description: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  headerText: {
    fontWeight: 500,
  },
  subHeader: {
    marginTop: theme.spacing(2),
  },
  subHeaderText: {
    fontWeight: 400,
    fontSize: '16px',
  },
  logo: {
    width: '100%',
    maxWidth: 900,
  },
  buttonContainer: {
    paddingTop: '45px',
  }
});

class NotFoundPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        className={classes.root}
        // rowSpacing={2}
      >
        <Grid item>
          <img src="/404.png" className={classes.logo} />
        </Grid>

        <Grid container className={classes.description}>
          <Grid item>
            <Typography variant="h5" className={classes.headerText}>
              Sorry... something went wrong!
            </Typography>
          </Grid>

          <Grid item className={classes.subHeader}>
            <Typography variant="h6" className={classes.subHeaderText}>
              Please try again or go to the previous opened page.
            </Typography>
          </Grid>

          <Grid item className={classes.buttonContainer} >
            <OrangeButton
              variant="contained"
              size="medium"
              onClick={Router.back}
            >
              Go Back
            </OrangeButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(NotFoundPage, styles);
